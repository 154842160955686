import { ProviderTechnician } from "@/model/api/Provider";
import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { providerTechniciansService } from "@services/providerTechnicians.service";
import { FilterMatchMode } from "primevue/api";
import { Options, Vue } from "vue-class-component";
import { TechniciansRoutesEnum } from "../../router/TechniciansRoutesEnum";

import {
  ActiveCell,
  UserDynamicTable,
  MyAutocomplete
} from "@components";

@Options({
  components: {
    UserDynamicTable,
    MyAutocomplete
  }
})
export default class AdminTechniciansPage extends Vue {
  readonly stateTableKey = 'AdminTechniciansPage';
  filters: any = this.filtersSchema;

  get datatable(): any {
    return this.$refs.datatable;
  }
  
  // beforeRouteLeave() {
  //   if (providerTechniciansService?.cancelPendingRequests) {
  //     providerTechniciansService.cancelPendingRequests();
  //   }
  // }

  beforeRouteEnter(to, from, next) {
    if (!(from?.name as string)?.startsWith(TechniciansRoutesEnum.ADMIN_PREFIX)) {
      sessionStorage.removeItem('AdminTechniciansPage');
    }
    next();
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  get service() {
    return providerTechniciansService;
  }
  
  goAdd() {
    this.$router.push({
      name: TechniciansRoutesEnum.ADMIN_TECHNICIAN_DETAILS,
      params: { technicianId: 0 }
    });
  }

  goToDetail(model: ProviderTechnician) {
    this.$router.push({
      name: TechniciansRoutesEnum.ADMIN_TECHNICIAN_DETAILS,
      params: { technicianId: model.id }
    });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  get cols() {
    return [
      {
        field: 'name',
        header: this.$t('user_name'),
        style: 'min-width: 10px; max-width: 150px'
      },
      {
        field: 'surname',
        header: this.$t('user_surname'),
        style: 'min-width: 10px; max-width: 100px'
      },
      /*
      {
        field: 'email',
        header: this.$t('generic.email_label'),
      },
      */
      {
        field: 'providers_text',
        header: this.$t('admin.technicians.table.providers'),
        filterField: 'provider_id'
      },
      {
        field: 'provinces_text',
        header: this.$t('admin.technicians.table.provinces'),
        filterField: 'province_id'
      },
      {
        field: 'services_text',
        header: this.$t('admin.technicians.table.services'),
        filterField: 'service_id'
      },
    ];
  }

  get filtersSchema() {
    return {
      name: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      surname: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      email: {
        matchMode: FilterMatchMode.CONTAINS
      },
      provider_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      province_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      service_id: {
        matchMode: FilterMatchMode.EQUALS
      },
    };
  }
}