import { ProviderTechnician } from "@/model/api/Provider";
import { CalendarRoutesEnum } from "@/modules/calendar/router";
import { OrdersRoutesEnum } from "@/modules/orders/router";
import { domainService } from "@services/domain.service";
import { providerTechniciansService } from "@services/providerTechnicians.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { RouteLocationRaw } from "vue-router";
import { TechniciansRoutesEnum } from "../../router";

@Options({})
export default class AdminTechnicianDetailsPage extends Vue {
  @Prop() readonly technicianId!: String;

  technician: ProviderTechnician = new ProviderTechnician();
  
  get isNew() {
    return this.technicianId == "0";
  }

  get servicesText() {
    return this.technician?.services?.map(x => x.name).join(", ");
  }

  get provincesText() {
    return this.technician?.provinces?.map(x => x.name).join(", ");
  }

  get providersText() {
    return this.technician?.providers?.map(x => x.business_name).join(", ");
  }

  get technicianStatus() {
    return this.technician?.is_active ? "Attivo" : "Inattivo";
  }

  get calendarRoute() {
    return {
      name: CalendarRoutesEnum.CALENDAR,
      query: {
        technicianId: this.technicianId?.toString(),
      }
    } as RouteLocationRaw;
  }

  get ordersRoute() {
    return {
      name: OrdersRoutesEnum.ORDERS,
      query: {
        technicianId: this.technicianId?.toString(),
        technicianName: `${this.technician?.name} ${this.technician?.surname}`
      }
    } as RouteLocationRaw;
  }

  onSave() {
    this.updateTechnician();
  }

  private updateTechnician() {
    return this.$waitFor( async () => {
    if (this.isNew) {
      await providerTechniciansService.create(this.technician);
    } else {
      await providerTechniciansService.update(this.technician);
    }

      this.$router.replace({
        name: TechniciansRoutesEnum.ADMIN_TECHNICIAN_LIST
      }).then( () => {
        this.$successMessage("Tecnico aggiornato correttamente");
      })
    });
  }

  private loadTechnician() {
    if (!this.isNew) {
      this.$waitFor( async () => {
        this.technician = await providerTechniciansService.getById(+this.technicianId);
      });
    } else {
      this.technician = new ProviderTechnician();
      this.technician.is_active = true;
    }
  }
  
  provinces: any[] = [];
  async searchProvince({ query }) {
    const resp = await domainService.getProvincesAutocomplete(
      query, null
    );

    this.provinces = [
      ...resp.data
    ];
  }

  created() {
    this.loadTechnician();
  }
}